<script setup>
import { Link, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import AppLayout from '@/Layouts/AppLayout.vue'
import { ref } from 'vue'
import LoginCard from '@/Shared/LoginCard.vue';
import RequestSuccessError from '@/Shared/RequestSuccessError.vue'

defineProps({
  canResetPassword: Boolean,
  status: String,
});

const form = useForm({
  email: '',
  password: '',
  usePassword: false,
  remember: false,
});

const submit = () => {
  let routeUsed = form.usePassword ? 'login' : 'login.magiclink.request';
  form.transform(data => ({
    ...data,
    remember: form.remember ? 'on' : '',
  })).post(route(routeUsed), {
    onFinish: () => form.reset('password'),
  });
};
</script>

<template>
  <AuthenticationCard>
    <div class="text-left text-xl mb-4">
    <span class="text-red-500" v-html="$t('alreadyRegistered')" /><br>
    <span class="text-red-800" v-html="$t('loginHere')" />
    </div>

    <RequestSuccessError :errors="form.hasErrors ? form.errors : null" />

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
    {{ status }}
    </div>

    <form @submit.prevent="submit" id="loginForm">
    <div>
      <InputLabel for="email" :value="$t('email')" />
      <TextInput
      id="email"
      v-model="form.email"
      type="email"
      class="mt-1 block w-full"
      required
      autocomplete="username"
      />
      <InputError class="mt-2" :message="form.errors.email" />
    </div>

    <div class="mt-4">
      <InputLabel for="usePassword">
        <div class="flex items-center">
          <Checkbox id="usePassword" v-model:checked="form.usePassword" name="usePassword" />
          <span class="ml-2 text-sm" v-html="$t('usePassword')"></span>
        </div>
      </InputLabel>
      <InputError class="mt-2" :message="form.errors.usePassword" />
    </div>

    <div v-if="form.usePassword" class="mt-4">
      <InputLabel for="password" :value="$t('password')" />
      <div class="flex relative">
        <TextInput
          id="passwordLogin"
          v-model="form.password"
          :type="showPwd ? 'text' : 'password'"
          class="mt-1 block w-full rounded-br-none rounded-tr-none"
          required
          autocomplete="current-password"
        />
        <button type="button" @click="togglePwd" class="pwShowHide leftStraight"><font-awesome-icon class="w-5" :icon="showPwd ? 'fa-eye-slash' : 'fa-eye'" /></button>
      </div>
      <InputError class="mt-2" :message="form.errors.password" />
    </div>

    <div v-if="form.usePassword" class="block mt-4">
      <label class="flex items-center">
      <Checkbox v-model:checked="form.remember" name="remember" />
      <span class="ml-2 text-sm text-gray-600" v-html="$t('rememberMe')" />
      </label>
    </div>

    <div class="flex items-center justify-end mt-4">
      <Link v-if="canResetPassword" :href="route('password.request')" class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {{ $t('forgotPasswordShort') }}
      </Link>

      <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        <span v-if="form.usePassword" v-html="$t('login')" />
        <span v-else v-html="$t('getLinkByMail')" />
      </PrimaryButton>
    </div>
    </form>
  </AuthenticationCard>
</template>

<script>
export default
{
  data() {
    return {
      showPwd: false
    }
  },
  methods:
  {
    togglePwd() {
      this.showPwd = !this.showPwd;
    }
  }
}
</script>
