<template>
  <a :href="'/auth/' + provider" @click="countAnalytics" class="no-underline w-full flex items-center bg-white border border-gray-300 rounded-lg shadow-md mt-4 p-4 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
    <svg class="h-6 w-6 mr-3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="viewBox" version="1.1">
      <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <slot />
      </g>
    </svg>
    <span class="text-base text-gray-600" v-html="$t('sso.' + provider)" />
  </a>
</template>

<script>
function countAnalytics()
{
  alert("aa")
  if (typeof gtag !== 'undefined')
  {
    gtag("event", "login", {method: this.provider});
  }
}

export default {
  props: ['provider', 'viewBox']
}
</script>
